import React, { useState, useEffect } from 'react';
import { makeAuthenticatedGETRequest } from '../utils/serverHelper';
import { PlaylistView, SingleCard, SongCard } from '../Components';

const AddToPlaylistModal = ({ closeModal }) => {
  const [myPlaylists, setMyPlaylists] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const response = await makeAuthenticatedGETRequest('/playlist/get/me');
      setMyPlaylists(response.data);
    };
    getData();
  }, []);
  console.log(myPlaylists);
  return (
    <div
      className="absolute bg-black w-screen h-screen bg-opacity-50 flex justify-center items-center "
      onClick={closeModal}
    >
      <div
        className="w-1/3 bg-app-black p-4 rounded-md text-white"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="text-white mb-5 font-semibold text-lg">
          Select Playlist
        </div>
        <div className="space-y-4 m-4 flex flex-col justify-center items-center">
          <PlaylistView />
        </div>
      </div>
    </div>
  );
};

export default AddToPlaylistModal;
