import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Howl } from 'howler';
import songContext from '../../Context/songContext';

const SongPlayer = ({ openModal }) => {
  const {
    currentSong,
    setCurrentSong,
    playing,
    setPlaying,
    audioOn,
    setAudioOn,
  } = useContext(songContext);

  const [showSoundbar, setShowSoundbar] = useState('hidden');
  const [soundMute, setSoundMute] = useState('ic:twotone-volume-up');
  const [repeatIcon, setRepeatIcon] = useState(
    'fluent:arrow-repeat-all-off-20-regular'
  );

  let i = useRef(0);
  const repeatIconHandler = () => {
    if (i.current === 0) {
      setRepeatIcon('fluent:arrow-repeat-all-20-filled');
      i.current++;
    } else if (i.current === 1) {
      setRepeatIcon('fluent:arrow-repeat-1-20-regular');
      i.current++;
    } else {
      setRepeatIcon('fluent:arrow-repeat-all-off-20-regular');
      i.current = 0;
    }
  };

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    // if statement will prevent first render execution.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!currentSong) {
      return;
    }
    changeSong(currentSong.track);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSong && currentSong.track]);

  const changeSong = (songSrc) => {
    if (audioOn) {
      audioOn.stop();
    }
    let sound = new Howl({
      src: [songSrc],
      html5: true,
    });
    setAudioOn(sound);
    sound.play();
    setPlaying(true);
  };

  const playSong = () => {
    if (!audioOn) {
      return;
    }
    audioOn.play();
  };

  const pauseSound = () => {
    audioOn.pause();
  };

  const soundEnterHandler = () => {
    setShowSoundbar('block');
  };

  const soundLeaveHandler = () => {
    setShowSoundbar('hidden');
  };

  const playButtonHandler = () => {
    if (playing === true) {
      pauseSound();
      setPlaying(false);
    } else {
      playSong(currentSong.track);
      setPlaying(true);
    }
  };

  const soundActionHandler = () => {
    if (soundMute === 'ic:twotone-volume-up') {
      setSoundMute('ic:baseline-volume-off');
    } else {
      setSoundMute('ic:twotone-volume-up');
    }
  };

  return (
    currentSong && (
      <div className="songPlayer w-full h-1/10 bg-black bg-opacity-30 text-white flex items-center px-4">
        <div className="leftSide flex items-center  w-1/4">
          <img
            src={currentSong.thumbnail}
            className="w-14 h-14 rounded-[12px]"
            alt={currentSong.name}
          />
          <div className="pl-4">
            <div className="hover:underline cursor-pointer truncate mb-2 text-base ">
              {currentSong.name}
            </div>
            <div className="hover:underline cursor-pointer text-sm text-gray-500 truncate hover:text-white">
              {currentSong.artist.firstName + ' ' + currentSong.artist.lastName}
            </div>
          </div>
          <div className="flex ml-8">
            <Icon
              icon="mdi:cards-heart-outline"
              width={25}
              className="text-gray-500 hover:text-red-700 m-2  hover:scale-125 active:scale-110  cursor-pointer"
            />
            <Icon
              icon="ic:round-playlist-add"
              width={27}
              onClick={openModal}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110  cursor-pointer"
            />
          </div>
        </div>
        <div className="playbackController w-1/2 h-full flex flex-col justify-center items-center ">
          <div className="flex items-center">
            <Icon
              icon="ph:shuffle-fill"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110  cursor-pointer"
            />
            <Icon
              icon="gg:play-track-prev"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
            <Icon
              icon={
                playing
                  ? 'material-symbols:pause-circle-rounded'
                  : 'material-symbols:play-circle-rounded'
              }
              width={40}
              onClick={playButtonHandler}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />

            <Icon
              icon="gg:play-track-next"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
            <Icon
              icon={repeatIcon}
              onClick={repeatIconHandler}
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
          </div>
          <div>ProgressBar</div>
        </div>
        <div className="rightSide w-1/4 flex justify-end items-center">
          <div className="flex justify-evenly w-1/2">
            <Icon
              icon="lucide:mic-2"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
            <Icon
              icon="heroicons:queue-list"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
            <Icon
              icon="tabler:devices-2"
              width={25}
              className="text-gray-500 hover:text-white m-2  hover:scale-125 active:scale-110 cursor-pointer"
            />
          </div>
          <div className="flex justify-between w-1/2">
            <Icon
              icon={soundMute}
              width={25}
              className="text-gray-500 hover:text-white   hover:scale-125 active:scale-110 cursor-pointer"
              onMouseEnter={soundEnterHandler}
              onMouseLeave={soundLeaveHandler}
              onClick={soundActionHandler}
            />
            <div
              className={`border ${showSoundbar} border-t-4 cursor-pointer w-full h-1 rounded mt-3 ml-2 border-gray-100`}
            ></div>
          </div>
        </div>
      </div>
    )
  );
};

export default SongPlayer;
