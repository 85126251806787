import { useState, useEffect } from 'react';

import { SongCard, SpotifyWrapper } from '../../Components';
import { makeAuthenticatedGETRequest } from '../../utils/serverHelper';

const LibraryComponent = () => {
  const [myPlaylists, setMyPlaylists] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const response = await makeAuthenticatedGETRequest('/playlist/get/me');
      setMyPlaylists(response.data);
    };
    getData();
  }, []);
  return (
    <SpotifyWrapper currActiveScreen={'Library'}>
      <h1 className="text-2xl font-semibold p-8 ">My Playlists</h1>
      <div className="Playlists grid gap-4 grid-cols-5 p-8 overflow-auto pt-0">
        {myPlaylists.map((item) => {
          return (
            <SongCard
              key={JSON.stringify(item)}
              gridClass={'w-full'}
              title={item.name}
              description={''}
              imgUrl={item.thumbnail}
            />
          );
        })}
      </div>
    </SpotifyWrapper>
  );
};

export default LibraryComponent;
